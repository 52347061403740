<template>
  <b-card :title="indicator.description">
    <div class="text-center">
      <b-spinner
        v-show="loading"
        style="width: 5rem; height: 5rem;"
        class="mt-4 mb-3"
      />
    </div>

    <div v-show="!loading">
      <indicator-hot-table
        ref="indicatorHotTable"
        :indicator="indicator"
        :meassurements="meassurementsAndBenchmarks.meassurements"
        :show-table="showTable"
        :saving="saving"
        @spreadsheet-data-update="onSpreadsheetDataUpdate"
        @save-click="save"
      />
    </div>
    <indicator-graph
      ref="indicatorChart"
      class="mt-4"
      :chart-data="chartData"
      :country-benchmarks="meassurementsAndBenchmarks.countryBenchmarks"
      :global-benchmarks="meassurementsAndBenchmarks.globalBenchmarks"
      :network-benchmarks="networkBenchmarks"
      :title="indicatorDescription"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IndicatorHotTable from './IndicatorHotTable.vue'
import IndicatorGraph from './IndicatorGraph.vue'
import meassurements from '../../services/meassurements'

export default {
  components: {
    BCard,
    IndicatorHotTable,
    IndicatorGraph,
    BSpinner,
  },
  props: {
    organization: {
      type: Object,
      default: () => null,
    },
    indicator: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      meassurements: null,
      showTable: false,
      chartData: [],
      meassurementsAndBenchmarks: {
        meassurements: null,
      },
      saving: false,
      loading: false,
    }
  },
  computed: {
    indicatorId() {
      return this.indicator ? this.indicator['.key'] : ''
    },
    organizationId() {
      return this.organization ? this.organization['.key'] : ''
    },
    networkBenchmarks() {
      return (this.meassurementsAndBenchmarks && this.meassurementsAndBenchmarks.networkBenchmarks)
        ? this.meassurementsAndBenchmarks.networkBenchmarks
        : {}
    },
    indicatorDescription() {
      return (this.indicator) ? this.indicator.description : ''
    },
  },
  watch: {
    indicatorId: {
      immediate: true,
      async handler(value) {
        this.showTable = false
        this.loading = true
        try {
          const result = await meassurements.getWithBenchmarks(this.organizationId, value)
          this.meassurementsAndBenchmarks = result.data
          this.buildSpreadsheetData(result.data.meassurements)
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al recuperar indicadores y/o benchmarks',
              text: err.message,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.loading = false
        }
      },
    },
  },
  methods: {
    buildSpreadsheetData(meassurementsObj) {
      this.$refs.indicatorHotTable.buildSpreadsheetData(meassurementsObj)
      this.showTable = true
    },
    async onSpreadsheetDataUpdate(meassurementsArray) {
      this.chartData = meassurementsArray
    },
    async save(savableData) {
      try {
        this.saving = true
        const result = await meassurements.save(this.organizationId, this.indicatorId, savableData)
        if (result.data.error) {
          this.showNotification('Error al guardar cambios', result.data.error, 'AlertCircleIcon', 'danger')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Listo',
              text: 'Los datos fueron guardados exitosamente',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al guardar indicadores',
            text: err.message,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.saving = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
