<template>
  <div id="user-profile">
    <custom-app-breadcrumb
      page-title=""
      :breadcrumb="breadcrumb"
    />
    <organization-header :header-data="header" />
    <b-row>
      <b-col lg="3">
        <indicators-filter
          :indicators="indicators"
          @select-indicator="onSelectIndicator"
        />
      </b-col>
      <b-col lg="9">
        <indicator-card
          v-if="selectedIndicator"
          :organization="organization"
          :indicator="selectedIndicator"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import CustomAppBreadcrumb from '../../layouts/components/CustomAppBreadcrumb.vue'
import OrganizationHeader from './OrganizationHeader.vue'
import IndicatorsFilter from '../../components/indicators/IndicatorsFilter.vue'
import IndicatorCard from './IndicatorCard.vue'
import organizations from '../../services/organizations'
import indicators from '../../services/indicators'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

/* eslint-disable global-require */

export default {
  components: {
    CustomAppBreadcrumb,
    OrganizationHeader,
    IndicatorsFilter,
    IndicatorCard,
    BRow,
    BCol,
  },
  data() {
    return {
      organization: null,
      logoUrl: '',
      indicators: [],
      selectedIndicator: null,
    }
  },
  computed: {
    organizationName() {
      return (this.organization) ? this.organization.name : ''
    },
    organizationLogoFilename() {
      return (this.organization) ? this.organization.logo : ''
    },
    breadcrumb() {
      return [
        {
          text: 'Instituciones',
          to: { name: 'organizations' },
        },
        {
          text: (this.organization) ? this.organization.name : '',
          active: true,
        },
      ]
    },
    header() {
      return {
        avatar: this.logoUrl,
        username: this.organizationName,
        designation: (this.organization) ? `${this.organization.city}, ${this.organization.country.name}` : '',
        coverImg: require('@/assets/images/profile/profile-bg.jpg'),
        section: 'indicators',
      }
    },
    groups() {
      return this.indicators.map(i => i.group).filter(onlyUnique).map(group => ({
        label: group,
        value: group,
      }))
    },
    groupCount() {
      return this.groups.length
    },
    filteredIndicators() {
      const self = this
      return this.indicators.filter(i => i.group === self.selectedGroup)
    },
  },
  watch: {
    async organizationLogoFilename(value) {
      if (this.organization) {
        this.logoUrl = await organizations.getLogoURL(this.$route.params.id, value)
      }
    },
    groupCount(value) {
      if (value > 0) {
        this.selectedGroup = this.groups[0].value
      }
    },
  },
  mounted() {
    this.$rtdbBind('organization', organizations.bindChild(this.$route.params.id))
    this.$rtdbBind('indicators', indicators.bindAll())
  },
  methods: {
    onSelectIndicator(indicatorId) {
      this.selectedIndicator = indicatorId
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '~@core/scss/base/pages/page-profile.scss';

// profile-header
#user-profile {
  .profile-header {
    .profile-header-nav {
      .navbar {
        .navbar-toggler {
          border: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
