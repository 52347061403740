<template>
  <div>
    <hot-table
      v-if="showTable"
      :settings="hotSettings"
    >
      <hot-column
        title="Año"
        data="year"
        :read-only="true"
      />
      <hot-column
        :title="indicator.inputs.value1"
        data="value1"
      />
      <hot-column
        :title="indicator.inputs.value2"
        data="value2"
      />
      <hot-column
        title="Valor"
        data="computedValue"
        :read-only="true"
      />
      <hot-column
        title="Meta"
        data="goal"
      />
    </hot-table>
    <b-form-row class="mt-1">
      <b-form-checkbox
        v-model="completed"
        :value="true"
        class="custom-control-success"
        style="margin-top: auto; margin-bottom: auto;"
      >
        Completado
      </b-form-checkbox>
      <b-button
        v-if="showTable"
        :disabled="saving"
        variant="gradient-success"
        class="ml-3"
        @click="onSaveClick"
      >
        Guardar cambios
        <b-spinner
          v-if="saving"
          small
        />
      </b-button>
      <xlsx-workbook>
        <xlsx-sheet
          v-for="sheet in downloadableSheets"
          :key="sheet.name"
          :collection="sheet.data"
          :sheet-name="sheet.name"
        />
        <xlsx-download>
          <b-button
            class="ml-2"
            variant="outline-secondary"
          >
            <feather-icon
              class="text-secondary"
              icon="DownloadIcon"
            />
            &nbsp;
            Descargar a Excel
          </b-button>
        </xlsx-download>
      </xlsx-workbook>
    </b-form-row>

  </div>
</template>

<script>
import { HotTable, HotColumn } from '@handsontable/vue'
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'

import {
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormRow,
} from 'bootstrap-vue'

export default {
  components: {
    HotTable,
    HotColumn,
    BButton,
    BSpinner,
    BFormCheckbox,
    BFormRow,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    indicator: {
      type: Object,
      default: () => null,
    },
    meassurements: {
      type: Object,
      default: () => null,
    },
    showTable: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hotSettings: {
        data: null,
        colHeaders: true,
        height: 'auto',
        afterChange: () => {
          this.updateSpredsheetData()
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
      completed: false,
    }
  },
  computed: {
    currentYear() {
      return this.$store.state.services.currentYear
    },
    downloadableSheets() {
      if (!this.hotSettings.data || this.hotSettings.data.length === 0) {
        return [{ name: 'sin datos', data: [{ }] }]
      }

      const spreadSheeetData = []

      this.hotSettings.data.forEach(r => {
        spreadSheeetData.push({
          Indicador: this.indicator.description,
          Año: r.year,
          Input_1: r.value1,
          Input_2: r.value2,
          Valor: (r.computedValue) ? r.computedValue : null,
          Meta: r.goal,
        })
      })

      return [
        {
          name: 'Indicador',
          data: spreadSheeetData,
        },
      ]
    },
  },
  methods: {
    buildSpreadsheetData(meassurementsObj) {
      const meassurementsArray = []
      this.completed = false
      Object.keys(meassurementsObj).forEach(k => {
        const o = {
          year: meassurementsObj[k].year,
          value1: meassurementsObj[k].value.value1,
          value2: meassurementsObj[k].value.value2,
          goal: meassurementsObj[k].value.goal,
          meassurementId: k,
        }
        o.computedValue = this.calculateComputedValue(this.indicator.type, o.value1, o.value2)
        if (!Object.keys(o).includes('goal')) {
          o.goal = ''
        }
        meassurementsArray.push(o)
        if (o.year === this.currentYear && meassurementsObj[k].value.completed) {
          this.completed = true
        }
      })
      this.hotSettings.data = meassurementsArray
      this.$emit('spreadsheet-data-update', meassurementsArray)
    },
    updateSpredsheetData() {
      if (!this.hotSettings.data) {
        this.buildSpreadsheetData(this.meassurements)
      } else {
        this.hotSettings.data = this.hotSettings.data.map(r => {
          const o = r
          o.computedValue = this.calculateComputedValue(this.indicator.type, r.value1, r.value2)
          return o
        })
      }
    },
    calculateComputedValue(indicatorType, value1, value2) {
      switch (indicatorType) {
        case '1':
          return +((value1 / value2) * 100).toFixed(1)
        case '2':
          return +(((value1 - value2) / value1) * 100).toFixed(1)
        case '3':
          return +(value1 / 1)
        case '4':
          return +(value1 / value2).toFixed(1)
        default:
          return '?'
      }
    },
    onSaveClick() {
      const savableData = {}
      this.hotSettings.data.forEach(r => {
        savableData[r.meassurementId] = { value: { value1: r.value1, value2: r.value2, goal: r.goal } }
        if (r.year === this.currentYear) {
          savableData[r.meassurementId].value.completed = this.completed
        }
      })
      this.$emit('save-click', savableData)
    },
  },
}
</script>

<style src="../../../node_modules/handsontable/dist/handsontable.full.css"></style>
