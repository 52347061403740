<template>
  <div>
    <b-card>
      <label class="text-info">
        1. Seleccione un grupo de indicadores
      </label>
      <v-select
        v-model="selectedGroup"
        :options="groups"
        :clearable="false"
        :reduce="val => val.value"
      />
      <label class="mt-2 text-info">
        2. Seleccione un indicador
      </label>
      <b-list-group>
        <b-list-group-item
          v-for="v in filteredIndicators"
          :key="v['.key']"
          @click="setSelectedIndicator(v)"
        >
          {{ v.description }}
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  props: {
    indicators: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedGroup: '',
      selectedIndicator: null,
    }
  },
  computed: {
    groups() {
      return this.indicators.map(i => i.group).filter(onlyUnique).map(group => ({
        label: group,
        value: group,
      }))
    },
    groupCount() {
      return this.groups.length
    },
    filteredIndicators() {
      const self = this
      return this.indicators.filter(i => i.group === self.selectedGroup)
    },
  },
  watch: {
    groupCount(value) {
      if (value > 0) {
        this.selectedGroup = this.groups[0].value
      }
    },
  },
  methods: {
    setSelectedIndicator(indicator) {
      this.selectedIndicator = indicator
      this.$emit('select-indicator', indicator)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
