// Firebase
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  bindAll() {
    const indicators = firebase.database().ref('indicators')
    return indicators
  },
}
