<template>
  <div>
    <e-charts
      ref="myChart"
      autoresize
      :manual-update="true"
      theme="theme-color"
      auto-resize
    />
    <b-button
      class="mt-1"
      variant="outline-secondary"
      size="sm"
      @click="downloadImage"
    >
      <feather-icon
        class="text-secondary"
        icon="DownloadIcon"
      />
      &nbsp;
      Descargar imagen
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
    BButton,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    countryBenchmarks: {
      type: Object,
      default: () => {},
    },
    globalBenchmarks: {
      type: Object,
      default: () => {},
    },
    networkBenchmarks: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    chartOptions() {
      if (this.chartData.length === 0) {
        return null
      }

      const xAxisData = this.chartData.map(m => m.year)
      const yComputedData = this.chartData.map(m => m.computedValue)
      const yGoalData = this.chartData.map(m => m.goal)
      const countryBenchmarksArray = []
      Object.keys(this.countryBenchmarks).forEach(k => {
        countryBenchmarksArray.push(this.countryBenchmarks[k])
      })
      const yCountryBenchmarkData = []
      xAxisData.forEach(year => {
        const item = countryBenchmarksArray.find(cb => cb.year === year)
        if (item && item.value.computedValue) {
          yCountryBenchmarkData.push(item.value.computedValue)
        } else {
          yCountryBenchmarkData.push(null)
        }
      })
      const globalBenchmarksArray = []
      Object.keys(this.globalBenchmarks).forEach(k => {
        globalBenchmarksArray.push(this.globalBenchmarks[k])
      })
      const yGlobalBenchmarkData = []
      xAxisData.forEach(year => {
        const item = globalBenchmarksArray.find(gb => gb.year === year)
        if (item && item.value.computedValue) {
          yGlobalBenchmarkData.push(item.value.computedValue)
        } else {
          yGlobalBenchmarkData.push(null)
        }
      })
      const options = {
        backgroundColor: '#fef1e1',
        title: {
          subtext: this.title,
          subtextStyle: {
            fontSize: 13,
          },
          left: 'left',
          bottom: '0',
          rich: {},
        },
        xAxis: [
          {
            type: 'category',
            data: xAxisData,
            splitLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: [
                  '#d8cbbb',
                ],
              },
            },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '45px',
        },
        series: [
          {
            name: 'Benchmark General',
            type: 'bar',
            data: yGlobalBenchmarkData,
            barMaxWidth: '20%',
            barMinWidth: '10px',
            color: '#67b9e8',
            itemStyle: {
              barBorderRadius: [3, 3, 0, 0],
            },
            label: {
              show: true,
              position: 'inside',
            },
          },
          {
            name: 'Valor',
            type: 'bar',
            data: yComputedData,
            barMaxWidth: '20%',
            barMinWidth: '10px',
            color: '#0074aa',
            itemStyle: {
              barBorderRadius: [3, 3, 0, 0],
            },
            label: {
              show: true,
              position: 'inside',
            },
          },
          {
            name: 'Benchmark País',
            type: 'bar',
            data: yCountryBenchmarkData,
            barMaxWidth: '20%',
            barMinWidth: '10px',
            color: '#c0c0c0',
            itemStyle: {
              barBorderRadius: [3, 3, 0, 0],
            },
            label: {
              show: true,
              position: 'inside',
            },
          },
          {
            name: 'Meta',
            type: 'line',
            data: yGoalData,
            color: '#bb5370',
            lineStyle: {
              color: '#bb5370',
            },
            label: {
              show: true,
              position: 'top',
              color: '#000000',
              textShadowColor: '#ffffff',
              textShadowBlur: 10,
            },
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          left: 0,
        },
      }
      const networkBenchmarksArray = []
      Object.keys(this.networkBenchmarks).forEach(k => {
        networkBenchmarksArray.push(this.networkBenchmarks[k])
      })
      if (networkBenchmarksArray.length > 0) {
        const yNetworkBenchmarkData = []
        xAxisData.forEach(year => {
          const item = networkBenchmarksArray.find(nb => nb.year === year)
          if (item && item.value.computedValue) {
            yNetworkBenchmarkData.push(item.value.computedValue)
          } else {
            yNetworkBenchmarkData.push(null)
          }
        })
        options.series.push({
          name: 'Benchmark Red',
          type: 'line',
          data: yNetworkBenchmarkData,
          color: '#83bf61',
          lineStyle: {
            color: '#83bf61',
          },
        })
      }
      return options
    },
  },
  watch: {
    chartOptions(newValue) {
      if (newValue) {
        this.$refs.myChart.mergeOptions(newValue, true)
      }
    },
  },
  methods: {
    downloadImage() {
      const canvas = document.getElementsByTagName('canvas')
      if (canvas && canvas.length > 0) {
        // create label
        const tempA = document.createElement('a')
        // Set download name
        tempA.download = 'indicador.png'
        // Set address and file type
        tempA.href = canvas[0].toDataURL('image/png')
        document.body.appendChild(tempA)
        // Trigger download event
        tempA.click()
        // Remove Tag
        tempA.remove()
      }
    },
  },
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
